.heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 60px 0 0px 0;
}

.screen-sub-heading {
    margin: 8px 0 18px 0;
    font-size: 0.87rem;
    letter-spacing: 0.02rem;
    color: var(--peach);
    font-family: 'Montserrat-Bold', sans-serif;
}

.screen-heading {
    font-size: 3.2rem;
    color: var(--light-tan);
    font-family: 'Montserrat-Black', sans-serif;
}

.heading-seperator {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    margin: 5px 0 0 0;
}

.seperator-line {
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: var(--brown);
    /* box-shadow: 1px 1px 5px 0px; */
}

.seperator-blob {
    height: 10px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
}

.seperator-blob div {
    width: 10px;
    border-radius: 50px;
    background-color: var(--light-tan);
    /* box-shadow: 2px 2px 8px 0px; */
}
@media only screen and (max-width: 380px) {
    .screen-heading {
        font-size: 2.5rem;
    }
    .screen-sub-heading {
        font-size: 0.75rem;
        letter-spacing: 0rem;
    }
    .heading-seperator {
        width: 190px;
        margin: 5px 0 0 0;
    }
}

