            .profile-container {
                animation-duration: 1s;
                animation-name: slidein;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: space-evenly;
                /*center*/
                height: 10%;
                min-height: -moz-fit-content;
                min-height: fit-content;
            }
            
            @keyframes slidein {
                from {
                    margin-top: 100%;
                    height: 300%;
                }
                to {
                    margin-top: 0%;
                    height: 100%;
                }
            }
            
            .profile-parent {
                display: flex;
                margin: 60px 0 150px 0;
                align-items: center;
                color: var(--charcoal);
            }
            
            .profile-details {
                text-align: center;
                margin-left: 3%;
            }
            
            .profile-details-name {
                font-size: 3.2rem;
                color: var(--charcoal);
                font-family: 'Montserrat', sans-serif;
                font-weight: 900;
            }
            
            .profile-details-role {
                display: flex;
                flex-direction: column;
                /* top | right | bottom | left */
                margin: 14px 0 24px 0;
            }
            
            .profile-job-title {
                font-size: 1.4rem;
                font-family: 'Montserrat-Black', sans-serif;
                color: var(--brown);
            }
            
            .profile-role-text {
                font-family: 'Roboto', sans-serif;
                font-size: 1rem;
                font-style: italic;
                letter-spacing: 0.01rem;
                padding: 0.1rem 1rem 1.95rem 0.9rem;
                color: var(--charcoal);
                font-weight: 400;
            }
            
            .highlighted-text {
                color: var(--peach);
                font-size: 3.2rem;
                font-family: 'Montserrat', sans-serif;
            }
            
            .profile-options .highlighted-btn {
                /* top | right | bottom | left */
                margin: 0 0 0 40px;
                border-radius: 25px;
            }
            
            .profile-options .highlighted-btn:hover {
                background-color: var(--peach);
                border-color: var(--peach);
                color: var(--charcoal);
            }
            
            .profile-picture {
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0.2rem 0.2rem 0rem 0.15rem var(--light-charcoal);
                border-radius: 25%;
                border: 3px var(--brown);
                height: 425px;
                width: 507px;
                margin: 0 100px 0 100px;
            }
            
            .profile-picture-background {
                height: 98%;
                width: 98%;
                border-radius: 25%; 
                background-image: url("../../../assets/images/Home/John-half-body-no-bg-crop-sm.png");
                background-size: cover;
                background-color: var(--light-tan);
                background-repeat: no-repeat;
                background-position: center;
            }
            
            .profile-picture-background:hover {
                transform: scale(1.06);
                transition: 0.5s ease-out;
            }
            .cols-icon{
                display: flex;
                justify-content: center;
            }
            .colz .cols-icon i {
                color: var(--charcoal);
                padding: 10px 35px 10px 0;
                font-size: 1.8rem;
            }
            
            .colz .cols-icon i:hover {
                transform: scale(1.07);
                color: var(--light-charcoal);
            }
            /*IPAD PRO RESPONSIVENESS*/
            
            @media only screen and (max-width: 1110px) {
                .profile-picture {
                    /* top | right | bottom | left */
                    margin: 0 25px 50px 0px;
                }
                .profile-details-name {
                    font-size: 3rem;
                }
                .profile-options .btn {
                    width: 150px;
                    padding: 15px 2px;
                    z-index: 700;
                }
            }
            
            @media only screen and (max-width: 910px) {
                .profile-picture {
                    margin: 10px 20px 0px 15px;
                    height: 315px;
                    width: 300px;
                }
                .profile-picture-background {
                    height: 98%;
                    width: 98%;
                }
            }
            
            @media only screen and (max-width: 810px) {
                .profile-picture {
                    margin: 0 !important;
                }
                .profile-parent {
                    flex-direction: column-reverse;
                    margin: 0px 0 75px 0;
                    width: 90%;
                }
                .profile-options {
                    display: flex;
                    justify-content: space-evenly;
                }
                .profile-details {
                    text-align: center;
                    margin: 35px 0;
                    font-size: 1.7rem;
                }
          
                .profile-options .highlighted-btn {
                    margin: 0 !important;
                }
                .profile-options .btn {
                    width: 120px;
                }
                .profile-details-name,
                .profile-details-role {
                    text-align: center;
                }
            }
            
            @media only screen and (max-width: 375px) {
                .profile-details {
                    text-align: center;
                    width: 90%;
                }
                .colz .cols-icon i {
                    padding: 10px 25px 10px 0;
                    font-size: 1.5rem;
                }
                .primary-text {
                    font-size: 2.7srem; 
                }
                .highlighted-text {
                    font-size: 2.9rem;
                }
                .profile-job-title {
                    font-size: 1.275rem;
                }
                .profile-picture {
                  
                    height: 295px;
                    width: 280px;
                }
                .profile-picture-background {
                    height: 96%;
                    width: 95%;
                }

                @media only screen and (max-width: 241px) {
                    .profile-picture {
                       display: none;
                    }
                    .profile-picture-background {
                        display: none;
                    }
                }
            }