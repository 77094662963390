.about-me-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.about-me-parent {
    max-width: 1200px;
    width: 90%;
}

.about-me-card {
    display: flex;
    width: 100%;
    margin-top: 50px;
    background-color: var(--light-tan);
    border-radius: 25px;
    border: 3px solid var(--brown);
}

.about-me-profile {
    width: 50%;
    background-image: url("../../assets/images/AboutMe/john-diving.png");
    opacity: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 22px 0 0 22px;
}

.about-me-description p {
    margin: 0.75rem 0.5rem 0.5rem 0.5rem;
    text-align: left;
}

.about-me-details {
    width: 65%;
    padding: 20px;
}

.about-me-quote p {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 0.95rem;
    font-style: italic;
    width: 100%;
    text-align: center;
    letter-spacing: 0.03rem;
    line-height: 1.2rem;
    padding: 8px 8px 8px 8px;
    background-color: var(--charcoal);
    border-radius: 25px;
    color: var(--light-tan);
    margin: 0rem 0rem 1.5rem 0rem;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem var(--charcoal);
}


.about-me-description {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 0.85rem;
    line-height: 1.3rem;
    letter-spacing: 0.02rem;
    color: var(--charcoal);
}


.about-me-highlights {
    margin: 10px 0;
}

.highlight-heading {
    font-family: 'Montserrat-Black', sans-serif;
    font-size: 1.15rem;
    text-align: left;
    margin: 0 0 5px 0;
    letter-spacing: 0.02rem;
    color: var(--brown);
}

.highlight {
    display: flex;
    align-items: center;
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 1rem;
    letter-spacing: 0.04rem;
    color: var(--charcoal);
}

.highlight-blob {
    height: 4px;
    width: 4px;
    margin: 0 10px 0 0;
    background-color: var(--brown);
    border-radius: 15px;
}

.about-me-options  {
    font-family: "Montserrat-SemiBold", sans-serif;
    font-size: 1rem;
    text-align: left;
    color: var(--brown);
    /* font-style: italic; */
}

.code-social {
    width: 12rem;
    padding: 0.5rem 1rem;
    background-color: var(--charcoal);
    border-radius: 5px;
    margin: 0.2rem 0.5rem 0 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.03rem var(--dark-bronze);
}
.hr {
    padding: 1.08rem 1rem;

}
.cta-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


/*IPAD PRO RESPONSIVENESS*/

@media only screen and (max-width: 1110px) {
    .about-me-parent {
        width: 88%;
    }
    .about-me-quote {
        width: auto;
        margin: 0rem 0 0.8rem 0;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
}

@media only screen and (max-width: 898px) {
    .about-me-profile {
        display: none;
    }
    .about-me-details {
        width: 100%;
        text-align: left;
        padding: 10px;
    }
    .about-me-container {
        margin: 0;
    }
    .about-me-options strong {
        font-size: 0.8rem;

    }
    .highlight-heading {
        font-size: 1.1rem;
    }
    .about-me-quote {
        margin: 0.1rem 0.5rem 0.2rem 0.5rem;
    }

    .highlight {
        font-size: 0.98rem;
    }

}

@media only screen and (max-width: 466px) {
    .code-social {
        border-radius: 25px;
        margin: 0 5px 0px 5px;
    }
    img.code-social  {
        width: 110px;


    }
}
