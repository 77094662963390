.footer-container {
    height: 17.8%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.footer-parent {
    width: 100%;
    height: 100%; 
}

.footer-parent img {
    width: 100%;
    height: 100%;
}


/*IPAD PRO RESPONSIVENESS*/

@media only screen and (max-width: 1110px) {
    .footer-parent {
        display: flex;
        align-items: flex-end;
    }
}