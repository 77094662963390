@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/* 
font-family: 'Montserrat', sans-serif;
font-family: 'Montserrat Alternates', sans-serif;
font-family: 'Roboto', sans-serif; 
*/

@font-face {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 300;
    src: url('./assets/Fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: "Montserrat-Black";
    font-style: normal;
    font-weight: 900;
    src: url('./assets/Fonts/Montserrat-Black.ttf');
}

@font-face {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 800;
    src: url('./assets/Fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: "Montserrat-SemiBold";
    font-style: normal;
    font-weight: 700;
    src: url('./assets/Fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 400;
    src: url('./assets/Fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: 500;
    src: url('./assets/Fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 800;
    src: url('./assets/Fonts/Roboto-Bold.ttf');
}

.container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

* {
    outline: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    overflow-y: overlay;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.82rem;
    background-color: var(--charcoal);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, 0.1);
}

.btn {
    border-radius: 25px;
    padding: 15px 0;
    width: 150px;
    cursor: pointer;
    transition: 0.2s;
}

.btn:active {
    transform: translateY(2px);
}

.primary-btn {
    color: var(--charcoal);
    border-radius: 25px;
    padding: 15px 0;
    background-color: var(--light-tan);
    border: 3px solid var(--charcoal);
    font-weight: 900;
    font-size: 0.9rem;
    font-family: 'Montserrat-Black', sans-serif;
}

.primary-btn:hover {
    color: var(--light-tan);
    background-color: var(--light-charcoal);
    border: 3px solid var(--light-charcoal);
}

.highlighted-btn {
    color: var(--light-tan);
    font-family: 'Montserrat-Black', sans-serif;
    font-weight: 900;
    font-size: 0.9rem;
    border: 3px solid var(--charcoal);
    border-radius: 25px;
    background-color: var(--charcoal);
}

@media only screen and (max-width: 568px) {
    .highlighted-btn {
        z-index: -900;
    }
}

.red-border {
    border: 2px solid var(--crimson) !important;
}

.fade-in {
    transform: translateY(80px);
    transition: all 0.8s ease;
}