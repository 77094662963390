.testimonial-section {
    padding: 90px 0 60px;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    background-color: var(--charcoal);
    z-index: -1;
}

.testimonial-section::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
    background-color: var(--charcoal);
}

.testimonial-section .testi-item {
    background-color: var(--light-tan);
    padding: 20px;
    border: 3px solid var(--brown);
    margin: 50px 15px 50px 15px;
    border-radius: 25px;
}

.testimonial-section .testi-item p {
    font-size: 0.97rem;
    line-height: 1.35rem;
    text-align: justify;
    color: var(--charcoal);
    font-family: 'Roboto-Regular', sans-serif;
}

.testimonial-section .testi-item p .fa-quote-left {
    margin: 0 10px 0px 0px;
    line-height: 1.85rem;
    font-size: 2.4rem;
    color: var(--brown);
}

.testimonial-section .client-info {
    position: relative;
    padding-left: 140px;
    padding-top: 5px;
    min-height: 50px;
}

.testimonial-section .client-info h5 {
    font-size: 1.1rem;
    line-height: 1rem;
    font-family: 'Montserrat-Bold', sans-serif;
    margin: 0 0 10px -10px;
    color: var(--brown);
}

.testimonial-section .client-info p {
    font-size: 0.85rem;
    line-height: 1rem;
    text-align: left;
    margin: 0;
    font-family: 'Roboto-Bold', sans-serif;
    color: var(--dark-bronze);
}