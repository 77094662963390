.resume-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: fit-content;
    /* margin: 0px 0; */
}

.resume-card {
    display: flex;
    height: 360px;
    max-width: 1200px;
    margin: 80px 0 0 0;
}

.resume-content {
    width: 90%;
}

.resume-bullets {
    height: 100%;
    border-radius: 25px;
    color: var(--charcoal);
    background-color: var(--light-tan);
    width: 320px;
}

.resume-bullet-details {
    flex-grow: 1;
    width: 880px;
    overflow: hidden;
    padding: 0 0 0 60px;
}

.experience-description {

    text-align: left;
}

.resume-description-text {
    font-size: 0.82rem;
}

.resume-details-carousal {
    display: flex;
    transition: transform 0.8s ease-out;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
}

.bullet-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--light-tan);
    border-radius: 25px;
    border: 3px solid var(--brown);
}

.bullets {
    width: 86%;
    position: relative;
    z-index: 2;
}

.bullet {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--charcoal);
    height: 50px;
    margin: 1.2rem 0;
    padding: 0.6rem;
    border-radius: 25px;
    width: 35px;
    transition: width 0.8s ease-in-out;
}
.bullet.selected-bullet {
    background-color: var(--charcoal);
    width: 100%;
}

.bullet-label {
    font-size: 1rem;
    white-space: nowrap;
    color: var(--charcoal);
    font-family: "Montserrat-Black", sans-serif;
}

.bullet-label:hover {
    color: var(--dark-bronze);
}

.bullet-logo {
    height: 16px;
    margin: 0 2rem 0.7rem 0.1rem;
}

.bullet-logo svg {
    font-size: 26px;
    padding-right: 3px;
}

.bullet-icons {
    width: 45px;
    height: 100%;
    z-index: 1;
    border-radius: 22px 0 0px 22px;
    background-color: var(--charcoal);
    position: absolute;
}

.resume-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 360px;
    width: 90%;
}

.resume-heading {
    display: flex;
    flex-direction: column;
}

.resume-main-heading {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.resume-main-heading span {
    display: flex;
    color: var(--light-tan);
    font-size: 1rem;
    font-family: "Roboto-Bold", sans-serif;
    align-items: flex-end;
}

.experience-description {
    max-width: 100%;
}

.resume-sub-heading {
    font-size: 0.95rem;
    font-family: "Roboto-Medium", sans-serif;
    color: var(--peach);
}

.resume-heading-description {
    font-size: 0.9rem;
    text-align: left;
    color: var(--light-tan);
    font-family: "Roboto-Regular", sans-serif;
}

.heading-date {
    background-color: var(--dark-bronze);
    padding: 2px 8px;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    color: var(--light-tan);
}

.skill-parent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40%;
}

.programming-skills-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.skill-percentage {
    position: relative;
    height: 5px;
    top: 15px;
    border-radius: 25px 25px;
    width: 95%;
    background-color: var(--skill-bg);
}

.skill-percentage .active-percentage-bar .percentage-bar-text {
    color: var(--charcoal);
    font-size: 0.8rem;
    padding-right: 10px;
}

.skill-percentage .active-percentage-bar {
    height: 15px;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: 0.8s ease;
    position: absolute;
    border-radius: 5px 15px 15px 5px;
    background-color: var(--skill-color);
}

.skill-parent span {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 1rem;
    color: var(--light-tan);
}


/* IPAD PRO RESPONSIVENESS */

@media only screen and (max-width: 1110px) {
    .resume-card {
        width: 95%;
    }
    .resume-bullet-details {
        padding: 0 0 0 70px;
    }
}


/* IPAD RESPONSIVENESS */

@media only screen and (max-width: 1023px) {
    .resume-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bullets {
        width: 90%;
    }
    .resume-details-carousal {
        align-items: center;
    }
    .resume-screen-container {
        justify-content: flex-start;
    }
    .resume-card {
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        width: 96%;
    }
    .resume-bullet-details {
        height: 360px;
        padding: 0;
        width: -moz-fit-content;
        width: fit-content;
    }
    .resume-main-heading span {
        font-size: 0.87rem;
    }
    .resume-sub-heading {
        font-size: 0.82rem;
    }

    .resume-heading-description {
        font-size: 0.75rem;
        margin: 0px 0 5px 0;
    }

    .heading-date {
        font-size: 0.87rem;
        text-align: center;
        padding: 0px 5px;
    }

    .resume-bullets {
        margin: 30px 0px;
        width: 100%;
    }
    .selected-bullet {
        width: 97%;
    }
    /* Small Mobiles */
    @media only screen and (max-width: 680px) {
        .resume-main-heading span {
            font-size: 0.72rem;
        }
        .resume-sub-heading {
            font-size: 0.7rem;
        }
        .resume-heading-description {
            font-size: 0.65rem;
            text-align: left;
        }
        .heading-date {
            font-size: 0.7rem;
            text-align: center;
            padding: 2px 5px;
        }
        .skill-percentage .active-percentage-bar .percentage-bar-text {
            font-size: 0.7rem;
            padding-right: 5px;
        }
    }
}