.header-container {
    display: flex;
    justify-content: center;
    height: 150px;
    color: var(--charcoal);
}

.header-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 70%;
    color: var(--charcoal);
}

.header-logo {
    font-size: 1.2rem;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
}
.header-logo img {
    height: 28%;
    width: 24%;
    margin-left: 5px;
}
.header-options {
    display: flex;
    align-items: center;
}

.header-option {
    font-size: 0.87rem;
    font-family: 'Montserrat-Black', sans-serif;
    cursor: pointer;
}

.header-hamburger {
    display: none;
    color: var(--charcoal);
}

.header-option-seperator {
    margin: 0 30px 0 0;
    color: var(--charcoal);
}
.header-option-seperator:hover {
   color: var(--dark-bronze);
}
.selected-header-option {
    color: var(--peach);
}


/*IPAD PRO RESPONSIVENESS*/

@media only screen and (max-width: 1110px) {
    .header-parent {
        width: 80%;
       
    }
}

@media only screen and (max-width: 970px) {
    .header-hamburger {
        display: block;
    }
    .header-parent {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        color: var(--charcoal);
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
    }
    
    .header-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -100%;
        transition: all 0.8s ease-out;
        top: 0px;
        bottom: 0px;
        height: 100vh;
        width: 100%;
        justify-content: space-around;
        z-index: 1000;
        opacity: 1;
    }
    .header-option-seperator {
        margin: 0;
        color: var(--light-tan);
    }
    .selected-header-option {
        color: var(--peach);
    }
    .header-option-seperator:hover {
        color: var(--dark-bronze);
    }
    .header-hamburger-bars {
        font-size: 2rem;
        cursor: pointer;
        margin: 0px -5px 0px 0;
        color: var(--charcoal);
    }
    .header-hamburger-bars:hover {
        color: var(--light-charcoal);
    }
    .show-hamburger-options {
        left: 0px;
        width: 100%;
        background-color: var(--charcoal);
        opacity: 0.95;
    }
 
}

@media only screen and (max-width: 375px) {
    .header-hamburger {
        display: block;
    }
    .header-hamburger-bars {
        font-size: 2rem;
        cursor: pointer;
        color: var(--charcoal);
    }
    .header-parent {
        width: 100%;
        margin-top: 0px;
        padding: 0px 10px;
        position: relative;
    }
    .header-logo {
        font-size: 1.3rem;
        text-align: left;
        font-weight: 900;
    }
    .header-logo img {
        height: 18%;
        width: 18%;
        margin-left: 5px;
    }
}

